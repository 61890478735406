<template>
    <div>
        <main>
            <Content_Component></Content_Component>
        </main>

        <footer>
            <!-- <div class="footrt_Partner">
                <div class="footer_Title">
                    <p>合作伙伴</p>
                </div>
            </div> -->
            <Footer_Compontent></Footer_Compontent>
        </footer>
    </div>
</template>

<script>
import Content_Component from '../components/Content_Component.vue'
import Footer_Compontent from "../components/Footer_Compontent.vue";
export default {
    name: "HomePage",
    components: { Content_Component, Footer_Compontent }
}
</script>

<style lang="scss" scoped>
.footrt_Partner {
    width: 100%;
    height: 440px;
    background: #313542;
    text-align: center;
    overflow: hidden;


    .footer_Title {
        width: 100%;
        margin-top: 62px;
        font-size: 30px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;

    }
}


</style>